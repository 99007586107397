<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1350px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ body.document_type.name }} :
              <b>{{ body.serie }}-{{ body.invoice_number }}</b></span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="body.document_type.name"
                      label="Tipo de Documento"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      label="Serie"
                      v-model="body.serie"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      label="Número "
                      v-model="body.invoice_number"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" v-if="isDisableEdit">
                    <v-text-field
                      label="Fecha"
                      v-model="body.invoice_date"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" v-else>
                    <date-field
                      :reset="reset"
                      label="Fecha"
                      v-model="body.invoice_date"
                      :rules="onlyRequired"
                      ref="ref_date"
                    ></date-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Sucursal"
                      v-model="body.company.second_name"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Proveedor"
                      v-model="body.provider.name"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Tipo"
                      :items="tipos"
                      item-text="name"
                      item-value="value"
                      v-model="body.type"
                      :readonly="isDisableEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Total"
                      v-model="body.amount"
                      :rules="onlyRequired"
                      ref="ref_amount"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Retención ISR"
                      v-model="body.isr"
                      :rules="isrRule"
                      ref="ref_isr"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Retención IVA"
                      v-model="body.iva"
                      :rules="ivaRule"
                      ref="ref_iva"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Descuento"
                      v-model="body.discount"
                      :rules="dscRule"
                      ref="ref_dsc"
                      :readonly="isDisableEdit"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <classification-field
                      v-if="body.classification != null"
                      v-model="classificationId"
                      required
                      :disabled="isDisableEdit"
                    ></classification-field>
                    <classification-field
                      v-else
                      v-model="classificationId"
                      :reset="reset"
                      required
                      :disabled="isDisableEdit"
                    ></classification-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      :value="
                        body.little_taxpayer ? 'PEQUEÑO CONTRIBUYENTE' : 'REGIMÉN GENERAL'
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Periodo Contable"
                      :items="periods"
                      :item-text="(item) => `${item.year} - ${item.month}`"
                      item-value="id"
                      v-model="body.working_period_id"
                      ref="ref_period"
                      :readonly="isDisableEdit"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Descripción de la Compra"
                      v-model="body.description"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- detalle fauca -->
                <v-container
                  v-if="
                    body.document_type_id == 10 ||
                    body.document_type_id == 9 ||
                    body.document_type_id == 8
                  "
                >
                  <v-row v-for="(dt, key) in body.detail" :key="key">
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="dt.description"
                        ref="ref_exempt"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Monto"
                        v-model="dt.amount"
                        ref="ref_exempt"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col cols="12" md="3" v-if="body.type_exempt_id != null">
                    <v-text-field
                      label="Tipo de Exento"
                      v-model="body.type_exempt.name"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" v-if="body.type_exempt_id != null">
                    <v-text-field
                      label="Exento"
                      v-model="body.exempt"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col class="text-right">
                    <b>
                      <span v-if="!body.payable && body.paid">PAGADA</span>
                      <span v-else>PENDIENTE DE PAGO</span>
                    </b>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()"> Cerrar </v-btn>
            <v-btn v-if="!isDisableEdit" color="blue darken-1" text @click="save()">
              Guardar Cambios
            </v-btn>
            <v-btn v-if="isDisableEdit" color="blue darken-1" text @click="enableEdit()">
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import request from "@/services/requests";
import rules from "@/utils/form.rules.js";
import requests from "../../../services/requests";
export default {
  components: {
    DateField: () => import("@/components/fields/DateField.vue"),
    ClassificationField: () => import("@/components/accounting/ClassificationField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    invoiceID: { type: String, requried: true },
  },
  data: () => ({
    isDisableEdit: true,
    loading: false,
    classificationId: {},
    reset: false,
    periods: [],
    periodId: {},
    body: {
      serie: "",
      invoice_number: "",
      company: {
        name: "",
      },
      invoice_date: "",
      description: "",
      provider: "",
      amount: "",
      balance: "",
      tax: "",
      discount: "",
      invoice_type: "",
      orders: [],
      vouchers: [],
      created_by: {
        username: "",
      },
      document_type: {
        name: "",
      },
      exempt: "",
      type_exempt: {
        name: "",
      },
      working_period: {
        year: "",
        month: "",
      },
      classification: {
        name: "",
      },
    },
    tipos: [
      { name: "Bien", value: "b" },
      { name: "Servicio", value: "s" },
    ],
    data: {},
    //rules
    onlyRequired: [rules.required, rules.minValue(0.01)],
    isrRule: [],
    ivaRule: [],
    dscRule: [],
    exemptRule: [],
  }),
  methods: {
    isNum(val) {
      let num = 0;
      if (isNaN(val)) {
        num = 0;
      } else {
        num = Number(val).toFixed(2);
      }
      return num;
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.isDisableEdit = true;
      this.classificationId = {};
      this.reset = false;
      this.isrRule = [];
      this.ivaRule = [];
      this.data = {};
      this.body = {
        serie: "",
        invoice_number: "",
        company: {
          name: "",
        },
        invoice_date: "",
        description: "",
        provider: "",
        amount: "",
        balance: "",
        tax: "",
        discount: "",
        invoice_type: "",
        orders: [],
        vouchers: [],
        created_by: {
          username: "",
        },
        document_type: {
          name: "",
        },
        exempt: "",
        type_exempt: {
          name: "",
        },
        working_period: {
          year: "",
          month: "",
        },
        classification: {
          name: "",
        },
      };
    },
    getData() {
      request.get("api/invoice/" + this.invoiceID).then((res) => {
        this.body = res.data;
        if (this.body.classification_id != null) {
          this.classificationId = this.body.classification;
        }
      });
    },
    getWorkingPeriod() {
      request.get("api/accounting/working-period/").then((res) => {
        if (res.status == 200) {
          this.periods = res.data.results;
        } else {
          console.error(res);
        }
      });
    },
    enableEdit() {
      if (this.body.working_period != null) {
        if (this.body.working_period.is_active) {
          this.isDisableEdit = false;
        } else {
          this.isDisableEdit = true;
        }
      } else {
        this.$toasted.global.info({
          message: "El periodo contable debe estar activo",
        });
      }
    },
    save() {
      this.data.type = this.body.type;
      this.data.classification_id = this.classificationId.id;
      this.data.working_period_id = this.body.working_period_id;
      this.data.amount = this.body.amount;
      this.data.isr = this.body.isr;
      this.data.iva = this.body.iva;
      this.data.invoice_date = this.body.invoice_date;
      this.data.serie = this.body.serie;
      this.data.invoice_number = this.body.invoice_number;
      requests.patch("api/invoice/" + this.invoiceID + "/", this.data).then((res) => {
        if (res.status == 200) {
          this.$toasted.global.info({
            message: "Operación realizada con éxito",
          });
          this.clear();
          this.getData();
        } else {
          this.$toasted.global.info({
            message: res.data.detail,
          });
        }
      });
    },
  },
  watch: {
    invoiceID: function (val) {
      if (val != undefined) {
        this.getData();
        this.getWorkingPeriod();
      }
    },
    body: {
      handler: function () {
        if (
          this.body.isr != undefined &&
          this.body.isr.length > 0 &&
          this.body.isr >= 0
        ) {
          this.isrRule = [];
        } else {
          this.isrRule = [rules.required, rules.minValue(0)];
        }
        if (this.body.iva != undefined && this.body.iva >= 0 && this.body.iva) {
          this.ivaRule = [];
        } else {
          this.ivaRule = [rules.required, rules.minValue(0)];
        }
        if (
          this.body.discount != undefined &&
          this.body.discount >= 0 &&
          this.body.discount > 0
        ) {
          this.discRule = [];
        } else {
          this.discRule = [rules.required, rules.minValue(0)];
        }
      },
      deep: true,
    },
  },
};
</script>
